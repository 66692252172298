import React from 'react';
import { AlertCircle, CheckCircle } from 'react-feather';
import TextLink from '../../TextLink';
import { getPolyScanTxLink, shortenTransaction } from '../../../maximus/utils';
import { colorMaximusDark, colorMint } from '../../../colors';
import useActiveWeb3React from '../../../hooks/connections/useActiveWeb3React';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { GrCircleAlert } from 'react-icons/gr';

interface TransactionPopupProps {
  hash: string;
  success?: boolean;
  summary?: string;
}

const TransactionPopup: React.FC<TransactionPopupProps> = ({
  hash,
  success,
  summary,
}) => {
  const { chainId } = useActiveWeb3React();

  return (
    <div className="tx-popup">
      <div className="tx-popup-icon">
        {success ? (
          <BsFillCheckCircleFill color="#fff" size="32" />
        ) : (
          <GrCircleAlert color="#fff" size="32" />
        )}
      </div>
      <div className="tx-popup-info">
        <span className="hash">{summary ?? shortenTransaction(hash)}</span>
        {chainId && (
          <TextLink href={getPolyScanTxLink(hash)}>View on Explorer</TextLink>
        )}
      </div>
    </div>
  );
};

export default TransactionPopup;
