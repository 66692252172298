import { useContext } from 'react'
import firebase from 'firebase/app'
import { Context } from '../contexts/FirebaseProvider'


const useFirebase = (): firebase.app.App => {
    const { app } = useContext(Context)

    // @ts-ignore
    return app
}

export default useFirebase
