import React, { useState } from "react";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { lightTheme, darkTheme, defaultStyles } from "../style/themes";
import GlobalStyles from "../style/globalStyles";

const CACHE_KEY = "IS_DARK";

const ThemeContext = React.createContext({
  isDark: null,
  toggleTheme: () => {},
});

const ThemeContextProvider: React.FC = ({ children }) => {
  const [isDark, setIsDark] = useState(() => {
    const isDarkUserSetting = localStorage.getItem(CACHE_KEY);
    return isDarkUserSetting ? JSON.parse(isDarkUserSetting) : true;
  });

  const toggleTheme = () => {
    setIsDark((prevState: any) => {
      localStorage.setItem(CACHE_KEY, JSON.stringify(!prevState));
      return !prevState;
    });
  };

  return (
    <ThemeContext.Provider value={{ isDark, toggleTheme }}>
      <SCThemeProvider
        theme={
          isDark
            ? { ...defaultStyles, ...darkTheme }
            : { ...defaultStyles, ...lightTheme }
        }
      >
        <GlobalStyles></GlobalStyles>
        {children}
      </SCThemeProvider>
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeContextProvider };
