import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import GlobalStyle from '../src/style/globalStyles';

declare global {
  interface Window {
    beta: boolean;
    ethereum: any;
    dev: boolean;
  }
}

window.beta = true;
ReactDOM.render(
  <React.StrictMode>
    <>
      <App />
    </>
  </React.StrictMode>,
  document.getElementById('root')
);
