export const lightTheme = {
  colors: {
    background: '#FFFFFF',
    primaryText: '#000000',
    secondaryText: '#000000',
    title: '#424856',
    subTitle: '#424856',
    gray: '#eeeeee',
    farmListBackground: '#FBFBFB',
    farmListRow: '#FFFFFF',
    maximusDim: '#FBFBFB',
    border: '#e3e3e5',
    greyText: '#424856',
    lightGreyText: '#C8C8C8',
    balanceText: '#424856',
    cardBorder: '1px solid #e3e3e5',
    divider: '#e3e3e5',
    modal: '#E3E3E5',
    activeButtonBG: '#FBFBFB',
    aprCard: '#fff',
    aprCardText: '#424856',
    walletIcon: 'rgba(66, 72, 86, 0.3)',
    quickModal: '#fff',
    loading: '#5C6477',
    selectBox: '#fff',
    selectBorder: '#ccc',
    selectText: '#424856',
  },
  body: '#FFF',
  text: '#363537',
  toggleBorder: '#FFF',
};

export const darkTheme = {
  colors: {
    background: '#1B1B1B',
    primaryText: '#FFFFFF',
    secondaryText: '#E3E3E5',
    title: '#E3E3E5',
    subTitle: '#E3E3E5',
    gray: '#eeeeee',
    maximusDim: '#424856',
    farmListBackground: '#292929',
    farmListRow: '#323232',
    border: '#292929',
    greyText: '#C8C8C8',
    lightGreyText: '#5C6477',
    balanceText: '#5C6477',
    cardBorder: '1px solid #292929',
    divider: '#323232',
    modal: '#292929',
    activeButtonBG: '#323232',
    aprCard: '#292929',
    aprCardText: '#989FB0',
    walletIcon: 'rgba(92, 100, 119, 0.3)',
    quickModal: '#292929',
    loading: '#E3E3E5',
    selectBox: '#323232',
    selectBorder: '#323232',
    selectText: '#E3E3E5',
  },
  body: '#363537',
  text: '#FAFAFA',
  toggleBorder: '#6B8096',
};

export const defaultStyles = {
  siteWidth: '1200px',
  mobileWidth: '768px',
  welcomeBarSize: '48px',
  topBarSize: '110px',
  footerSize: '120px',
  borderRadius: '12px',
  tokenRadius: '12px',
  bannerSize: '42px',
  badgeSize: '38px',
  chartHeight: '300px',
  noticeHeight: '46px',
  spacing0: '2px',
  spacing1: '4px',
  spacing2: '8px',
  spacing3: '16px',
  spacing4: '24px',
  spacing5: '32px',
  spacing6: '48px',
  spacing7: '64px',
  white: '#fff',
  black: '#000',
  base: '#070307',
  baseDim: '#da6814',
  card: '#0f0c0d',
  card70: 'rgba(15, 12, 13, 0.7)',
  mint: '#FFB600',
  purple: '#2b233f',
  placeholderBase: '#212121',
  placeholderPoint: '#424242',
  maximusLight: '#facfd8',
  maximusDark: '#e41e4d',
  maximusDim: '#930b2c',
  statusOk: '#58d68d',
  statusWarning: '#eb984e',
  statusAlert: '#e74c3c',
  grey50: '#f4f4f4',
  grey100: '#f9fafb',
  grey200: '#eeeeee',
  grey300: '#e0e0e0',
  grey400: '#c8c8c8',
  grey500: '#999999',
  grey600: '#666666',
  grey700: '#616161',
  grey800: '#424242',
  grey900: '#262626',
  darkYellow: '#FF9900',
  sushi: 'rgb(250, 82, 160)',
  quick: 'rgb(0, 116, 217)',
  fonts: {
    baseUrl:
      'https://fonts.googleapis.com/css2?family=Roboto:wght@200;400;600;700&display=swap',
    baseFamily: 'Roboto',
    secondUrl:
      'https://fonts.googleapis.com/css2?family=Lora:wght@400;500;600&display=swap',
    secondFamily: 'Lora',
  },
};

// $siteWidth: 1200px;
// $mobileWidth: 768px;
// $topBarSize: 74px;
// $footerSize: 100px;
// $borderRadius: 18px;
// $tokenRadius: 12px;
// $bannerSize: 42px;
// $badgeSize: 38px;
// $chartHeight: 300px;
// $noticeHeight: 46px;
// $spacing0: 2px;
// $spacing1: 4px;
// $spacing2: 8px;
// $spacing3: 16px;
// $spacing4: 24px;
// $spacing5: 32px;
// $spacing6: 48px;
// $spacing7: 64px;
// $white: #fff;
// $black: #000;
// $base: #070307;
// $baseDim: #da6814;
// $card: #0f0c0d;
// $card70: rgba(15, 12, 13, 0.7);
// $mint: #ffd93b;
// $purple: #2b233f;
// $placeholderBase: #212121;
// $placeholderPoint: #424242;
// $maximusLight: #facfd8;
// $maximusDark: #e41e4d;
// $maximusDim: #930b2c;
// $statusOk: #58d68d;
// $statusWarning: #eb984e;
// $statusAlert: #e74c3c;
// $grey50: #f4f4f4;
// $grey100: #f9fafb;
// $grey200: #eeeeee;
// $grey300: #e0e0e0;
// $grey400: #c8c8c8;
// $grey500: #999999;
// $grey600: #666666;
// $grey700: #616161;
// $grey800: #424242;
// $grey900: #262626;
// $sushi: rgb(250, 82, 160);
// $quick: rgb(0, 116, 217);
