import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle(
  ({ theme }) => `
    @import url(${theme.fonts.baseUrl});
    @import url(${theme.fonts.secondUrl});

    * {
      padding: 0;
      margin: 0;
      list-style: none;
      font-family: ${theme.fonts.baseFamily};
      transition: 0.3s;
    }

    *:focus { outline: none; }

    html {
      height: 100%;
    }

    body {
      height: 100%;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
    }

    #root {
      min-height: 100vh;
    }
  `
);
