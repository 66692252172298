/***
 * address
 * */
import { assetTokens } from "../../assets/tokens";
import { MaximusPool, MaximusPoolAPY, MaximusZap } from "./types";

export const PriceCalculatorAddress: string =
  "0xeABe3A541058f6894C97Ac11c257C7A3755f1741";
export const WETH: string = "0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619";
export const WMATIC: string = "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7";
export const BUNNY: string = "0x885d748C00A279B67A7749EC6b03301700dd0455";

export const DashboardAddress: string =
  "0xa0f7b64BCF8560eaEa6Cc4614d7301be3F58B7EB";

export const TokenMinterAddress: string =
  "0x1f0FAB7d12B17d7754AaD88f5386A9C6193e8c6f";

export const Zaps: Record<string, string> = {
  Lydia: "0x45029b6d4A5f1bA428761bd9a8CA08a6f5e94102",
  Pangolin: "0x10cE24220fBF0C19b3CdC33B3A8A8689C500B245",
  TraderJoe: "0xFaF0d63821E06e44749A5b571202CBfb12a19036",
};
export const Routers: Record<string, string> = {
  Lydia: "0xA52aBE4676dbfd04Df42eF7755F01A3c41f28D27",
  Pangolin: "0xE54Ca86531e17Ef3616d22Ca28b0D458b6C89106",
  TraderJoe: "0x60aE616a2155Ee3d9A68541Ba4544862310933d4",
};

export const PARTNERS_LINK = {
  discord: "https://discord.gg/HUYu6UJSZy",
  telegram: "https://t.me/MaximusFarm",
  twitter: "https://twitter.com/MaximusFarm",
};

export const EMPTY_APY_DATA = {
  apr: "000.00",
  aprPool: "000.00",
  aprMaximus: "000.00",
  aprSwap: "000.00",
  apy: "000.00",
  apyPoolComp: "000.00",
  apyPoolBorrow: "000.00",
  apyPoolMaximus: "000.00",
  apyPool: "000.00",
  apyMaximus: "000.00",
  apySwap: "000.00",
} as MaximusPoolAPY;

export const STORAGE_KEY_APY_DATA = "apy_data";
export const STORAGE_KEY_TVL_DATA = "tvl_data";

export const CloudEndpoint =
  "https://us-central1-bunny-polygon.cloudfunctions.net/api-data";

/**
 * links
 * */
export const LinkMaximusTokenInfo =
  "https://info.lydia.finance/tokens/0x885d748c00a279b67a7749ec6b03301700dd0455";

/**
 * wallet symbols
 * */
export const supportedWalletImageSymbols = [
  "WMATIC",
  "USDC",
  "ETH",
  "USDT",
  "BTC",
  "DAI",
];

export const tokens: Record<string, any> = require("./data/tokens.json");
export const supportedTokens = Object.keys(tokens).map((name) => {
  const token = tokens[name];
  return {
    icon: assetTokens[name],
    name: name,
    address: token.address,
  };
});

export const pools: Record<string, any> = require("./data/pools.json");
export const supportedPools: MaximusPool[] = Object.keys(pools).map((name) => {
  const pool = pools[name];
  return {
    icon: assetTokens[name],
    name: name,
    address: pool.address,
    token: pool.token,
    deposit: pool.deposit ? pool.deposit : name,
    earn: pool.earn,
    summary: pool.summary ? pool.summary : null,
    description: pool.description ? pool.description : null,
    exchange: pool.exchange ? pool.exchange : null,
    type: pool.type,
    chainId: pool.chainId,
    relay: pool.relay ? pool.relay : null,
    relayToken: pool.relayToken ? pool.relayToken : null,
    path: name.replace(/-| /gi, "-"),
    closed: pool.closed,
    swap: pool.swap,
    tokens: pool.tokens,
    farm_icon: pool.farm_icon,
    finished: pool.finished,
  };
});

const makeZapItem = (name: string, zap: any) => {
  return {
    name: name,
    icons: zap.symbols.map((symbol: string) => assetTokens[symbol]),
    symbols: zap.symbols,
    address: zap.address ? zap.address : null,
    virtual: zap.virtual ? zap.virtual : false,
    zapIn: zap.zapIn ? zap.zapIn : [],
    zapInToken: zap.zapInToken ? zap.zapInToken : [],
    zapOut: zap.zapOut ? zap.zapOut : [],
    wrap: zap.wrap ?? [],
    unwrap: zap.unwrap ?? [],
    index: zap.index,
    decimals: zap.decimals ? zap.decimals : [18],
  };
};

export const zaps: Record<string, any> = require("./data/zaps.json");
export const supportedZaps: MaximusZap[] = Object.keys(zaps).map((name) =>
  makeZapItem(name, zaps[name])
);

export const pangolinZaps: Record<
  string,
  any
> = require("./data/pangolinZaps.json");
export const supportedPangolinZaps: MaximusZap[] = Object.keys(
  pangolinZaps
).map((name) => makeZapItem(name, pangolinZaps[name]));

export const traderJoeZaps: Record<
  string,
  any
> = require("./data/traderJoeZaps.json");
export const supportedTraderJoeZaps: MaximusZap[] = Object.keys(
  traderJoeZaps
).map((name) => makeZapItem(name, traderJoeZaps[name]));
