import { LineData, UTCTimestamp } from "lightweight-charts";
import { BigNumber } from "../utils";

export enum PoolTypes {
  MaximusAVAX = "MaximusAVAX",
  MaximusStake = "MaximusStake",
  MaximusToMaximus = "MaximusToMaximus",
  FlipToFlip = "flipToFlip",
  Maximus = "Maximus",
  FlipToJoe = "flipToJoe",
  FlipToPng = "flipToPng",
  FlipToLyd = "flipToLyd",
}

export interface MaximusPool {
  icon: string;
  name: string;
  address: string;
  token: string;
  deposit: string;
  earn: string;
  summary: string;
  description: string;
  exchange: string;
  type: PoolTypes;
  chainId: number;
  relay?: string;
  relayToken?: string;
  path?: string;
  closed?: boolean;
  swap: string;
  tokens: Array<string>;
  farm_icon: string;
  finished?: boolean;
}

export interface MaximusPoolAPY {
  apr?: string;
  aprPool?: string;
  aprMaximus?: string;
  aprSwap?: string;
  apy?: string;
  apyPool?: string;
  apyPoolComp?: string;
  apyPoolBorrow?: string;
  apyPoolMaximus?: string;
  apyMaximus?: string;
  apySwap?: string;
}

export interface MaximusPoolValue extends MaximusPoolAPY {
  balance: BigNumber;
  principal: BigNumber;
  available: BigNumber;
  tvl: BigNumber;
  utilized: BigNumber;
  liquidity: BigNumber;
  pBASE: BigNumber;
  pMAXIMUS: BigNumber;
  portfolio: BigNumber;
  depositedAt?: number;
  feeDuration?: number;
  feePercentage?: number;

  // compensation
  compTokens?: string[];
  compPendings?: BigNumber[];
}

export interface MaximusPoolWithValue extends MaximusPool, MaximusPoolValue {}

export interface MaximusZap {
  name: string;
  icons: string[];
  symbols: string[];
  address: string;
  virtual: boolean;
  zapIn: string[];
  zapInToken: string[];
  zapOut: string[];
  wrap: string[];
  unwrap: string[];
  index: number;
  decimals: number[];
  swap?: string;
}

export interface PortfolioItem extends LineData {
  block: number;
  timestamp: number;
  weekly: boolean;
  monthly: boolean;
  time: UTCTimestamp;
  value: number;
}

export enum Swap {
  Lydia = "Lydia",
  Pangolin = "Pangolin",
  TraderJoe = "TraderJoe",
}
