import BigNumber from "bignumber.js";
import { isAddress } from "@ethersproject/address";
import { AddressZero, MaxUint256 } from "@ethersproject/constants";
import { TransactionReceipt } from "@ethersproject/providers";
import {
  EMPTY_APY_DATA,
  supportedPools,
  supportedPangolinZaps,
  supportedWalletImageSymbols,
  supportedZaps,
  supportedTraderJoeZaps,
  Zaps,
  BUNNY,
} from "./lib/constants";
import { TransactionDetails } from "../state/transactions/reducer";
import { ChainId, polyRPC } from "../connections/connectors";
import Maximus from "./Maximus";
import {
  MaximusPool,
  MaximusPoolAPY,
  MaximusPoolValue,
  MaximusPoolWithValue,
  MaximusZap,
  PoolTypes,
  Swap,
} from "./lib/types";
import { TransactionResponse } from "@ethersproject/abstract-provider";

BigNumber.config({
  EXPONENTIAL_AT: [-100, 100],
  DECIMAL_PLACES: 18,
});

const DUST = new BigNumber(1000);
const ZERO = new BigNumber(0);
const ETHER = new BigNumber(10).pow(18);
const UINT_MAX = new BigNumber(MaxUint256.toString());
const EMPTY_ADDRESS = AddressZero;
export { BigNumber, ZERO, ETHER, UINT_MAX, EMPTY_ADDRESS, DUST };

export const POLY_GUIDE =
  "https://support.avax.network/en/articles/4626956-how-do-i-set-up-metamask-on-avalanche";
export const getPolyScanAddressLink = (address: string) =>
  `https://cchain.explorer.avax.network/address/${address}`;
export const getPolyScanTxLink = (hash: string) =>
  `https://cchain.explorer.avax.network/tx/${hash}`;

const PROJECT_NAMES = {
  TraderJoe: "trader_joe",
  Pangolin: "pangolin",
  Lydia: "lydia",
};

/**
 * Display
 * */
export const addComma = (numString: string) => {
  let numParts = numString.split(".");
  numParts[0] = numParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return numParts.join(".");
};

export const shortenAddress = (address: string) => {
  if (!address) return "";
  return `${address.substring(0, 6)}...${address.substring(
    address.length - 4,
    address.length
  )}`;
};

export const shortenTransaction = (hash: string) => {
  if (!hash) return "";
  return hash.slice(0, 8) + "..." + hash.slice(58, 65);
};

export const toDisplayBalanceComma = (
  balance: BigNumber | number,
  decimals = 18,
  digits: boolean = true
): string => {
  const balanceBigNumber: BigNumber =
    typeof balance === "number" ? new BigNumber(balance).times(ETHER) : balance;
  const displayBalance = balanceBigNumber.dividedBy(
    new BigNumber(10).pow(decimals)
  );
  if (displayBalance.lt(1)) {
    return digits ? displayBalance.toPrecision(4) : displayBalance.toString();
  } else {
    return addComma(
      digits ? displayBalance.toFixed(2) : displayBalance.toFixed(0)
    );
  }
};

export const toDisplayBalance = (balance: BigNumber, decimals = 18): string => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  if (displayBalance.lt(1)) {
    return displayBalance.toPrecision(4);
  } else {
    return displayBalance.toFixed(2);
  }
};

export const toFullDisplayBalance = (
  balance: BigNumber,
  decimals = 18
): string => {
  return balance.dividedBy(new BigNumber(10).pow(decimals)).toFixed();
};

export const toBalanceFromDisplay = (val: string, decimals = 18): BigNumber => {
  const value = new BigNumber(val);
  return value.dividedBy(new BigNumber(10).pow(18 - decimals));
};

export const toDisplayNumber = (balance: BigNumber, decimals = 18): number => {
  const displayBalance = balance.dividedBy(new BigNumber(10).pow(decimals));
  return displayBalance.toNumber();
};

export const toDisplayAPYPercent = (percent: BigNumber): string => {
  return percent.times(100).div(ETHER).toFixed(2);
};

export const toDisplayPercent = (percent: number | BigNumber): string => {
  if (!percent) return "-.--";
  if (typeof percent === "number") {
    return percent.toFixed(2);
  } else {
    return (percent as BigNumber).times(100).div(ETHER).toFixed(2);
  }
};

export const isMore = (target: string, base: string): boolean => {
  return new BigNumber(target).gt(new BigNumber(base));
};

/**
 * Portfolio
 * */
export const getPortfolioEvaluated = async (
  maximus: Maximus
): Promise<BigNumber> => {
  if (!isAddress(maximus.account)) throw new Error("Invalid Account");

  try {
    const dashboardBSC = maximus.contracts.getDashboardContract();

    const evaluatedBSC = await dashboardBSC.portfolioOf(
      maximus.account,
      supportedPools.map((each) => each.address)
    );
    return new BigNumber(evaluatedBSC.toString());
  } catch (ex) {
    throw ex;
  }
};

/**
 * network
 * */
export const setupMATICNetwork = async (): Promise<boolean> => {
  try {
    const provider = window.ethereum;
    if (!provider) return false;

    await provider.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${ChainId.POLY.toString(16)}`,
          chainName: "Polygon Network",
          nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
          rpcUrls: [polyRPC],
          blockExplorerUrls: ["https://cchain.explorer.avax.network/"],
        },
      ],
    });
    return true;
  } catch (ex) {
    console.warn("ex", ex, setupMATICNetwork.name);
    return false;
  }
};

/**
 * transactions
 * */
export const calculateGasMargin = (value: any): BigNumber =>
  new BigNumber(value.toString()).times(150).idiv(100);

export const fetchTxReceipt = async (
  maximus: Maximus,
  hash: string
): Promise<TransactionReceipt> => {
  return await maximus.contracts.library.getTransactionReceipt(hash);
};

export const isRecentTransaction = (tx: TransactionDetails): boolean => {
  return new Date().getTime() - tx.addedTime < 86_400_000;
};

export const sortByNewTransactions = (
  a: TransactionDetails,
  b: TransactionDetails
) => {
  return b.addedTime - a.addedTime;
};

/**
 * erc20
 * */
export const getTokenBalance = async (
  maximus: Maximus,
  token: string
): Promise<BigNumber> => {
  if (!maximus || !maximus.account) return ZERO;
  try {
    if (!token) {
      return ZERO;
    } else if (token === EMPTY_ADDRESS) {
      const bnbBalance = await maximus.contracts.library.getBalance(
        maximus.account
      );
      return new BigNumber(bnbBalance.toString());
    } else {
      const tokenContract = maximus.contracts.getTokenReadOnlyContract(token);
      const tokenBalance = await tokenContract.balanceOf(maximus.account);
      return new BigNumber(tokenBalance.toString());
    }
  } catch (ex) {
    console.warn("ex", ex, getTokenBalance.name);
    return ZERO;
  }
};

export const getTokenBalanceInUSD = async (
  maximus: Maximus,
  token: string,
  balance: string,
  chainId: ChainId
): Promise<BigNumber> => {
  try {
    const priceCalculator = maximus.contracts.getPriceCalculator();
    const { 1: valueInUSD } = await priceCalculator.valueOfAsset(
      token,
      balance
    );
    return new BigNumber(valueInUSD.toString());
  } catch (ex) {
    console.warn("ex", ex, getTokenBalanceInUSD.name);
    return ZERO;
  }
};

export const getTokenAllowance = async (
  maximus: Maximus,
  token: string,
  spender: string
): Promise<BigNumber> => {
  if (!maximus || !maximus.account) return ZERO;

  try {
    if (!token) {
      return ZERO;
    } else if (token === EMPTY_ADDRESS) {
      return UINT_MAX;
    } else {
      const tokenContract = maximus.contracts.getTokenReadOnlyContract(token);
      const tokenAllowance = await tokenContract.allowance(
        maximus.account,
        spender
      );
      return new BigNumber(tokenAllowance.toString());
    }
  } catch (ex) {
    console.warn(getTokenAllowance.name, ex);
    return ZERO;
  }
};

export const getMaximusPrice = async (maximus: Maximus): Promise<BigNumber> => {
  try {
    const priceCalculator = maximus.contracts.getPriceCalculator();
    const maximusPrice = await priceCalculator.priceOfMaximus();
    return new BigNumber(maximusPrice.toString());
  } catch (ex) {
    return ZERO;
  }
};

export const getMaximusTotalSupply = async (
  maximus: Maximus
): Promise<BigNumber> => {
  try {
    const token = maximus.contracts.getMaxi();
    const totalSupply = await token.totalSupply();
    return new BigNumber(totalSupply.toString());
  } catch (ex) {
    return ZERO;
  }
};

export const getAvaxPrice = async (maximus: Maximus): Promise<BigNumber> => {
  try {
    const priceCalculator = maximus.contracts.getPriceCalculator();
    const avaxPrice = await priceCalculator.priceOfAVAX();
    return new BigNumber(avaxPrice.toString());
  } catch (ex) {
    return ZERO;
  }
};

export const getFreThreshold = async (maximus: Maximus): Promise<BigNumber> => {
  try {
    const tokenMinter = maximus.contracts.getTokenMinterContract();
    const fre = await tokenMinter.freThreshold();
    return new BigNumber(fre.toString());
  } catch (ex) {
    return ZERO;
  }
};

// TODO parameter ordering
export const registerTokenToWallet = async (
  maximus: Maximus,
  symbol: string,
  address: string
) => {
  try {
    if (!address || address === EMPTY_ADDRESS) return;

    const tokenContract = maximus.contracts.getTokenReadOnlyContract(address);
    const decimals = await tokenContract.decimals();

    const loweredSymbol = symbol
      .trim()
      .toLowerCase()
      .replaceAll(" flip", "")
      .replaceAll(" lp", "")
      .replaceAll("-", "_");

    const image = supportedWalletImageSymbols.includes(symbol)
      ? `https://raw.githubusercontent.com/PancakeBunny-finance/Bunny/main/assets/wallet-logo-${loweredSymbol}.png`
      : undefined;
    // @Danny FLIP to LP (zaps.json)
    let symbolInWallet = symbol.includes("FLIP")
      ? "UNI-V2"
      : symbol.includes("LP")
      ? "UNI-V2"
      : symbol;

    // @ts-ignore
    await maximus.contracts.library.provider.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20",
        options: {
          address: address,
          symbol: symbolInWallet,
          decimals: decimals,
          image: image,
        },
      },
    } as any);
  } catch (ex) {
    console.warn("ex", ex, registerTokenToWallet.name);
  }
};

export const getPools = async (
  maximus: Maximus,
  chainId: number,
  apyData: Record<string, MaximusPoolAPY> = {}
): Promise<MaximusPoolWithValue[]> => {
  try {
    const account = maximus.account ? maximus.account : EMPTY_ADDRESS;
    const dashboardContract = maximus.contracts.getDashboardContract();
    const filteredPools = supportedPools.filter((pool) => pool.address);
    const responses: any[] = await dashboardContract.poolsOf(
      account,
      filteredPools.map((each) => each.address)
    );
    const maxiPrice = await getMaximusPrice(maximus);

    const result = responses.map((info, index: number) => {
      const pool = filteredPools[index];
      const poolValue: MaximusPoolValue = {
        balance: ZERO,
        principal: ZERO,
        available: ZERO,
        tvl: ZERO,
        utilized: ZERO,
        liquidity: ZERO,
        pBASE: ZERO,
        pMAXIMUS: ZERO,
        portfolio: ZERO,
        depositedAt: 0,
        feeDuration: 0,
        feePercentage: 0,
        //@ts-ignore
        ...(apyData[PROJECT_NAMES[pool.swap]][pool.token] || EMPTY_APY_DATA),
      };

      try {
        poolValue.balance = new BigNumber(info.balance.toString());
        poolValue.principal = new BigNumber(info.principal.toString());
        poolValue.available = new BigNumber(info.available.toString());
        poolValue.tvl = new BigNumber(info.tvl.toString());
        poolValue.pBASE = new BigNumber(info.pBASE.toString());
        poolValue.pMAXIMUS = new BigNumber(info.pMAXIMUS.toString());
        poolValue.portfolio = new BigNumber(info.portfolio.toString());
        poolValue.depositedAt = info.depositedAt.toNumber();
        poolValue.feeDuration = info.feeDuration.toNumber();
        poolValue.feePercentage = info.feePercentage.toNumber() / 100;

        if (pool.type === PoolTypes.Maximus) {
          //@ts-ignore
          poolValue.apr =
            //@ts-ignore
            (1000 * 100) / poolValue.tvl.div(1e18).toNumber();
          //@ts-ignore
          poolValue.apy =
            //@ts-ignore
            aprToApy(poolValue.apr, 2190);
        } else if (pool.type === PoolTypes.MaximusAVAX) {
          const rewardPerYear =
            0.002 * 60 * 60 * 24 * 365 * (maxiPrice.toNumber() / 1e18);
          //@ts-ignore
          poolValue.apr =
            Number(poolValue.apr) +
            (rewardPerYear * 100) / poolValue.tvl.div(1e18).toNumber();
          //@ts-ignore
          poolValue.apy =
            //@ts-ignore
            aprToApy(poolValue.apr, 2190);

          //@ts-ignore
          if (poolValue.apy > 9999) {
            //@ts-ignore
            poolValue.apy = 9999;
          }
        }
      } catch (e) {
        if (window.dev) {
          console.warn(pool.name, e);
        }
      }
      return { ...pool, ...poolValue };
    });

    return result.filter((each) => !!each);
  } catch (ex) {
    if (window.dev) {
      console.log("ex", ex, getPools.name);
    }
    return [];
  }
};

//@ts-ignore
export const aprToApy = (interest, frequency = BLOCKS_IN_A_YEAR) =>
  ((1 + interest / 100 / frequency) ** frequency - 1) * 100;

export const getPool = async (
  maximus: Maximus,
  name: string,
  apyData: Record<string, MaximusPoolAPY> = {}
): Promise<MaximusPoolWithValue | null> => {
  const account = maximus.account ? maximus.account : EMPTY_ADDRESS;
  const pool = supportedPools.find((pool) => pool.name === name);
  if (!pool) return null;
  const maxiPrice = await getMaximusPrice(maximus);

  const poolValue: MaximusPoolValue = {
    balance: ZERO,
    principal: ZERO,
    available: ZERO,
    tvl: ZERO,
    utilized: ZERO,
    liquidity: ZERO,
    pBASE: ZERO,
    pMAXIMUS: ZERO,
    portfolio: ZERO,
    depositedAt: 0,
    feeDuration: 0,
    feePercentage: 0,
    //@ts-ignore
    ...(apyData[PROJECT_NAMES[pool.swap]][pool.token] || EMPTY_APY_DATA),
  };

  try {
    const dashboardContract = maximus.contracts.getDashboardContract();
    const info = await dashboardContract.infoOfPool(pool.address, account);
    poolValue.balance = new BigNumber(info.balance.toString());
    poolValue.principal = new BigNumber(info.principal.toString());
    poolValue.available = new BigNumber(info.available.toString());
    poolValue.tvl = new BigNumber(info.tvl.toString());
    poolValue.pBASE = new BigNumber(info.pBASE.toString());
    poolValue.pMAXIMUS = new BigNumber(info.pMAXIMUS.toString());
    poolValue.portfolio = new BigNumber(info.portfolio.toString());
    poolValue.depositedAt = info.depositedAt.toNumber();
    poolValue.feeDuration = info.feeDuration.toNumber();
    poolValue.feePercentage = info.feePercentage.toNumber() / 100;

    if (pool.type === PoolTypes.Maximus) {
      //@ts-ignore
      poolValue.apr =
        //@ts-ignore
        (1000 * 100) / poolValue.tvl.div(1e18).toNumber();
      //@ts-ignore
      if (poolValue.apr > 9999) {
        //@ts-ignore
        poolValue.apr = 9999;
      }
      //@ts-ignore
      poolValue.apy =
        //@ts-ignore
        aprToApy(poolValue.apr, 2190);

      //@ts-ignore
      if (poolValue.apy > 9999) {
        //@ts-ignore
        poolValue.apy = 9999;
      }
    } else if (pool.type === PoolTypes.MaximusAVAX) {
      const rewardPerYear =
        0.002 * 60 * 60 * 24 * 365 * (maxiPrice.toNumber() / 1e18);
      //@ts-ignore
      poolValue.apr =
        Number(poolValue.apr) +
        (rewardPerYear * 100) / poolValue.tvl.div(1e18).toNumber();
      //@ts-ignore
      poolValue.apy =
        //@ts-ignore
        aprToApy(poolValue.apr, 2190);

      //@ts-ignore
      if (poolValue.apy > 9999) {
        //@ts-ignore
        poolValue.apy = 9999;
      }
    }

    return { ...pool, ...poolValue };
  } catch (e) {
    if (window.dev) {
      console.warn(pool.name, e);
    }

    return { ...pool, ...poolValue };
  }
};

export const poolApprove = async (
  maximus: Maximus,
  pool: MaximusPool
): Promise<TransactionResponse | any> => {
  try {
    const maxUint256 = MaxUint256.toString();
    const tokenContract = maximus.contracts.getTokenContract(pool.token);

    const gasOptions = await maximus.estimateTxGas(
      tokenContract.estimateGas.approve(pool.address, maxUint256)
    );
    return await tokenContract.approve(pool.address, maxUint256, {
      ...gasOptions,
    });
  } catch (ex) {
    console.warn("ex", ex, poolApprove.name);
    return undefined;
  }
};

export const poolDeposit = async (
  maximus: Maximus,
  pool: MaximusPool,
  amount: string
): Promise<TransactionResponse | any> => {
  try {
    const amountWithDecimals = new BigNumber(amount).times(ETHER).toString();
    const strategyContract = maximus.contracts.getStrategyContract(
      pool.address
    );

    if (pool.token === EMPTY_ADDRESS) {
      const gasOptions = await maximus.estimateTxGas(
        strategyContract.estimateGas.depositBNB({ value: amountWithDecimals })
      );
      return await strategyContract.depositBNB({
        value: amountWithDecimals,
        ...gasOptions,
      });
    } else {
      const gasOptions = await maximus.estimateTxGas(
        strategyContract.estimateGas.deposit(amountWithDecimals)
      );
      return await strategyContract.deposit(amountWithDecimals, {
        ...gasOptions,
      });
    }
  } catch (ex) {
    console.warn("ex", ex, poolDeposit.name);
    return undefined;
  }
};

export const poolWithdraw = async (
  maximus: Maximus,
  pool: MaximusPool,
  amount: string
): Promise<TransactionResponse | any> => {
  try {
    const amountWithDecimals = new BigNumber(amount).times(ETHER).toString();
    const strategyContract = maximus.contracts.getStrategyContract(
      pool.address
    );

    const gasOptions = await maximus.estimateTxGas(
      strategyContract.estimateGas.withdrawUnderlying(amountWithDecimals)
    );
    return await strategyContract.withdrawUnderlying(amountWithDecimals, {
      ...gasOptions,
    });
  } catch (ex) {
    console.warn("ex", ex, poolWithdraw.name);
    return undefined;
  }
};

export const maxiPoolWithdraw = async (
  maximus: Maximus,
  pool: MaximusPool,
  amount: string
): Promise<TransactionResponse | any> => {
  try {
    const amountWithDecimals = new BigNumber(amount).times(ETHER).toString();
    const strategyContract = maximus.contracts.getStrategyContract(
      pool.address
    );

    const gasOptions = await maximus.estimateTxGas(
      strategyContract.estimateGas.withdraw(amountWithDecimals)
    );
    return await strategyContract.withdraw(amountWithDecimals, {
      ...gasOptions,
    });
  } catch (ex) {
    console.warn("ex", ex, poolWithdraw.name);
    return undefined;
  }
};

export const poolClaim = async (
  maximus: Maximus,
  pool: MaximusPool
): Promise<TransactionResponse | any> => {
  try {
    const strategyContract = maximus.contracts.getStrategyContract(
      pool.address
    );

    const gasOptions = await maximus.estimateTxGas(
      strategyContract.estimateGas.getReward()
    );
    return await strategyContract.getReward({ ...gasOptions });
  } catch (ex) {
    console.warn("ex", ex, poolClaim.name);
    return undefined;
  }
};

export const poolRedeem = async (
  maximus: Maximus,
  pool: MaximusPool
): Promise<TransactionResponse | any> => {
  try {
    const strategyContract = maximus.contracts.getStrategyContract(
      pool.address
    );

    const gasOptions = await maximus.estimateTxGas(
      strategyContract.estimateGas.withdrawAll()
    );
    return await strategyContract.withdrawAll({ ...gasOptions });
  } catch (ex) {
    console.warn("ex", ex, poolRedeem.name);
    return undefined;
  }
};

const _getZapList = (_swap: Swap = Swap.Lydia) => {
  if (_swap === Swap.Pangolin) {
    return supportedPangolinZaps;
  } else if (_swap === Swap.TraderJoe) {
    return supportedTraderJoeZaps;
  }
  return supportedZaps;
};

/**
 * Zap
 * */
export const getZapItems = (
  selected: MaximusZap | undefined = undefined,
  swap: Swap | undefined = undefined
): MaximusZap[] => {
  try {
    const zapList = _getZapList(swap);
    if (selected) {
      const merged = [
        ...zapList.filter((zap) => selected.zapIn.includes(zap.name)),
        ...zapList.filter((zap) => selected.zapInToken.includes(zap.name)),
        ...zapList.filter((zap) => selected.zapOut.includes(zap.name)),
        ...zapList.filter((zap) => selected.wrap.includes(zap.name)),
        ...zapList.filter((zap) => selected.unwrap.includes(zap.name)),
      ];
      return merged.sort((a, b) => {
        if (a.index < b.index) return -1;
        if (a.index > b.index) return 1;
        return 0;
      });
    } else {
      return zapList.filter((zap) => !zap.virtual);
    }
  } catch {
    return [];
  }
};

export const zapApprove = async (
  maximus: Maximus,
  tokenIn: MaximusZap,
  swap: Swap
): Promise<TransactionResponse | any> => {
  try {
    const maxUint256 = MaxUint256.toString();
    const tokenContract = maximus.contracts.getTokenContract(tokenIn.address);

    const zapAddress = Zaps[swap];
    const gasOptions = await maximus.estimateTxGas(
      tokenContract.estimateGas.approve(zapAddress, maxUint256)
    );
    return await tokenContract.approve(zapAddress, maxUint256, {
      ...gasOptions,
    });
  } catch (ex) {
    console.warn("ex", ex, zapApprove.name);
    return undefined;
  }
};

export const zapTokenRoute = async (
  maximus: Maximus,
  tokenIn: MaximusZap,
  tokenOut: MaximusZap,
  amount: string,
  swap: Swap
): Promise<TransactionResponse | any> => {
  try {
    let decimals = 18;
    if (tokenIn.address && tokenIn.address !== EMPTY_ADDRESS) {
      const tokenContract = maximus.contracts.getTokenContract(tokenIn.address);
      decimals = await tokenContract.decimals();
    }

    const amountWithDecimals = new BigNumber(amount)
      .times(new BigNumber(10).pow(decimals))
      .toString();
    const zapContract = maximus.contracts.getMaximusZap(swap);

    if (tokenIn.zapIn.includes(tokenOut.name)) {
      // MATIC -> others
      const gasOptions = await maximus.estimateTxGas(
        zapContract.estimateGas.zapIn(tokenOut.address, {
          value: amountWithDecimals,
        })
      );
      return await zapContract.zapIn(tokenOut.address, {
        ...gasOptions,
        value: amountWithDecimals,
      });
    } else if (tokenIn.zapInToken.includes(tokenOut.name)) {
      // others -> others
      const gasOptions = await maximus.estimateTxGas(
        zapContract.estimateGas.zapInToken(
          tokenIn.address,
          amountWithDecimals,
          tokenOut.address
        )
      );
      return await zapContract.zapInToken(
        tokenIn.address,
        amountWithDecimals,
        tokenOut.address,
        { ...gasOptions }
      );
    } else if (tokenIn.zapOut.includes(tokenOut.name)) {
      // others -> MATIC
      const gasOptions = await maximus.estimateTxGas(
        zapContract.estimateGas.zapOut(tokenIn.address, amountWithDecimals)
      );
      return await zapContract.zapOut(tokenIn.address, amountWithDecimals, {
        ...gasOptions,
      });
    } else if (tokenIn.wrap.includes(tokenOut.name)) {
      // MATIC -> wMATIC
      const wmaticContract = maximus.contracts.getWMATICContract();
      const gasOptions = await maximus.estimateTxGas(
        wmaticContract.estimateGas.deposit({ value: amountWithDecimals })
      );
      return await wmaticContract.deposit({
        ...gasOptions,
        value: amountWithDecimals,
      });
    } else if (tokenIn.unwrap.includes(tokenOut.name)) {
      // wMATIC -> MATIC
      const wmaticContract = maximus.contracts.getWMATICContract();
      const gasOptions = await maximus.estimateTxGas(
        wmaticContract.estimateGas.withdraw(amountWithDecimals)
      );
      return await wmaticContract.withdraw(amountWithDecimals, {
        ...gasOptions,
      });
    }
  } catch (ex) {
    console.warn("ex", ex, zapTokenRoute.name);
    return undefined;
  }
};

export const getAmountsOutTokens = async (
  maximus: Maximus,
  amountIn: string,
  path: string[],
  swap: Swap
): Promise<any> => {
  try {
    const amountWithDecimals = new BigNumber(amountIn).times(ETHER).toString();
    const routerV2 = maximus.contracts.getRouterV2ContractReadOnly(swap);
    const res = await routerV2.getAmountsOut(amountWithDecimals, path);
    return new BigNumber(res[path.length - 1].toString());
  } catch (ex) {
    console.warn("ex", ex, getAmountsOutTokens.name);
    return undefined;
  }
};
