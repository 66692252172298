import React, { lazy, Suspense, useCallback, useState } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { Provider } from 'react-redux';

import { createWeb3ReactRoot, Web3ReactProvider } from '@web3-react/core';
import { NetworkContextName } from './connections';
import getLibrary from './connections/getLibrary';

import FirebaseProvider from './contexts/FirebaseProvider';
import MaximusProvider from './contexts/MaximusProvider';
import ModalsProvider from './contexts/ModalsProvider';
import { ThemeContextProvider } from './contexts/ThemeContextProvider';

import Web3ReactManager from './components/Web3ReactManager';
import Popups from './components/Popups';
import TopAlert from './components/TopAlert';

import TransactionUpdater from './state/transactions/updater';
import Web3SideProvider from './contexts/Web3SideProvider';
import DataProvider from './contexts/DataProvider';
import store from './state';

const WelcomeBar = lazy(() => import('./components/WelcomeBar'));
const TopBar = lazy(() => import('./components/TopBar'));
const MobileMenu = lazy(() => import('./components/MobileMenu'));
const Farms = lazy(() => import('./views/Farms'));
const Zap = lazy(() => import('./views/Zap'));

const Web3ProviderNetwork = createWeb3ReactRoot(NetworkContextName);

if ('ethereum' in window) {
  (window.ethereum as any).autoRefreshOnNetworkChange = false;
}

const App: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleDismissMobileMenu = useCallback(
    () => setMobileMenu(false),
    [setMobileMenu]
  );
  const handlePresentMobileMenu = useCallback(
    () => setMobileMenu(true),
    [setMobileMenu]
  );

  return (
    <Providers>
      <Suspense fallback={<div />}>
        <Router>
          <TopAlert />
          <WelcomeBar />
          <TopBar onPresentMobileMenu={handlePresentMobileMenu} />
          <MobileMenu
            onDismiss={handleDismissMobileMenu}
            visible={mobileMenu}
          />

          <Switch>
            {/*<Route path='/presale' component={Auction}/>*/}
            <Route path="/zap" component={Zap} />
            <Route path="/pool" component={Farms} />

            <Route path="*">
              <Redirect to="/pool" />
            </Route>
          </Switch>
        </Router>
      </Suspense>

      <Popups />
      <SingletonHooksContainer />
    </Providers>
  );
};

const Web3Providers: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Web3ProviderNetwork getLibrary={getLibrary}>
        <Web3SideProvider>
          <Web3ReactManager>{children}</Web3ReactManager>
        </Web3SideProvider>
      </Web3ProviderNetwork>
    </Web3ReactProvider>
  );
};

const Providers: React.FC = ({ children }) => {
  return (
    <FirebaseProvider>
      <DataProvider>
        <Provider store={store}>
          <Web3Providers>
            <TransactionUpdater />
            <MaximusProvider>
              <ThemeContextProvider>
                <ModalsProvider>{children}</ModalsProvider>
              </ThemeContextProvider>
            </MaximusProvider>
          </Web3Providers>
        </Provider>
      </DataProvider>
    </FirebaseProvider>
  );
};

export default App;
