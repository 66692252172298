import React, { createContext, useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";

import { MaximusPoolAPY } from "../maximus/lib/types";
import {
  CloudEndpoint,
  STORAGE_KEY_APY_DATA,
  STORAGE_KEY_TVL_DATA,
} from "../maximus/lib/constants";
import axios, { AxiosResponse } from "axios";
import test from './apy.json';

export interface APYDataContext {
  apyData?: any;
  perfData?: number;
  mcapData?: number;
  tvlData?: number;
}

export const Context = createContext<APYDataContext>({ apyData: undefined });

const DataProvider: React.FC = ({ children }) => {
  const [apyData, setAPYData] =
    // @ts-ignore
    useState<any>(undefined);
  const [tvlData, setTVLData] = useState<number>(0);

  const fetchMetrics = useCallback(async () => {
    try {
      const response = {data: test, tvl: undefined}

      if (response?.data) {
        localStorage.setItem(
          STORAGE_KEY_APY_DATA,
          JSON.stringify(response.data)
        );
        setAPYData(response.data);
      }

      // if (response?.data?.tvl) {
      //   localStorage.setItem(
      //     STORAGE_KEY_TVL_DATA,
      //     JSON.stringify(response.data.tvl)
      //   );
      //   setTVLData(response.data.tvl);
      // }
    } catch {
      console.error("apyData");
    }
  }, [setAPYData, setTVLData]);

  useEffect(() => {
    const interval = setInterval(async () => {
      fetchMetrics();
    }, 300000);

    const cachedAPYData =
      // @ts-ignore
      JSON.parse(localStorage.getItem(STORAGE_KEY_APY_DATA)) ?? {};
    if (!isEmpty(cachedAPYData)) setAPYData(cachedAPYData);

    const cachedTVLData = parseFloat(
      localStorage.getItem(STORAGE_KEY_TVL_DATA) ?? "0"
    );
    if (cachedTVLData !== 0 && !isNaN(cachedTVLData)) setTVLData(cachedTVLData);

    fetchMetrics();
    return () => clearInterval(interval);
  }, [fetchMetrics]);

  return (
    <Context.Provider
      value={{
        apyData: apyData,
        tvlData: tvlData,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default DataProvider;
