import React, { createContext, useEffect, useState } from "react";
import useActiveWeb3React from "../hooks/connections/useActiveWeb3React";
import Maximus from "../maximus/Maximus";
import useFirebase from "../hooks/useFirebase";
import useSideWeb3 from "../hooks/connections/useSideWeb3";
import { shortenAddress } from "../maximus/utils";

export interface MaximusContext {
  maximus?: Maximus;
}

export const Context = createContext<MaximusContext>({ maximus: undefined });

const MaximusProvider: React.FC = ({ children }) => {
  const [maximus, setMaximus] = useState<any>();

  const { account, library } = useActiveWeb3React();
  const { polygon } = useSideWeb3();
  const firebase = useFirebase();

  useEffect(() => {
    if (!library) return;

    // @ts-ignore
    setMaximus(new Maximus(account, library, polygon));
  }, [library, account, polygon]);

  useEffect(() => {
    if (account) {
      firebase.analytics().setUserId(shortenAddress(account), { global: true });
    }
  }, [account, firebase]);

  return (
    <Context.Provider value={{ maximus: maximus }}>{children}</Context.Provider>
  );
};

export default MaximusProvider;
