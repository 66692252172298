import MAXI from './token-maxi.svg';
import USDT from './token-usdt.svg';
import WBTC from './token-wbtc.svg';
import ETH from './token-eth.svg';
import USDC from './token-usdc.svg';
import DAI from './token-dai.svg';
import AAVE from './token-aave.svg';
import INCH from './token-1inch.svg';
import AAVAXB from './token-aavaxb.svg';
import AVAX from './token-avax.svg';
import AVME from './token-avme.svg';
import BOOFI from './token-boofi.svg';
import JOE from './token-joe.svg';
import LINK from './token-link.svg';
import LYD from './token-lyd.svg';
import MIM from './token-mim.svg';
import MYAK from './token-myak.svg';
import ORCA from './token-orca.svg';
import PEFI from './token-pefi.svg';
import PNG from './token-png.svg';
import QI from './token-qi.svg';
import SNOB from './token-snob.svg';
import SUSHI from './token-sushi.svg';
import TIME from './token-time.svg';
import UNI from './token-uni.svg';
import VSO from './token-vso.svg';
import WAWAX from './token-wawax.svg';
import XAVA from './token-xava.svg';
import YAK from './token-yak.svg';
import YFI from './token-yfi.svg';

export const assetTokens: Record<string, string> = {
  MAXI,
  ETH,
  WBTC,
  USDC,
  USDT,
  DAI,
  AAVE,
  INCH,
  AAVAXB,
  AVAX,
  AVME,
  BOOFI,
  JOE,
  LINK,
  LYD,
  MIM,
  MYAK,
  ORCA,
  PEFI,
  PNG,
  QI,
  SNOB,
  SUSHI,
  TIME,
  UNI,
  VSO,
  WAWAX,
  XAVA,
  YAK,
  YFI,
};
